import { render, staticRenderFns } from "./Restaxzip.vue?vue&type=template&id=30893a64&scoped=true&lang=pug"
import script from "./Restaxzip.vue?vue&type=script&lang=ts"
export * from "./Restaxzip.vue?vue&type=script&lang=ts"
import style0 from "./Restaxzip.vue?vue&type=style&index=0&id=30893a64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30893a64",
  null
  
)

export default component.exports