
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FToggle from "@/components/f/Toggle.vue";

@Component({
  components: {
    LItem,
    FToggle,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'mid_allowance';
    this._need = true;
    this.auth_shift = false;
    this.auth_nencho = false;
  }
}
