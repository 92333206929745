
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    LItem,
    FSelect,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Prop() branch_hash: any;
  created() {
    this._title = 'main_branch';
    this._need = true;
  }
}
