
import { Inject } from '@/library/provide';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LProgress from "@/components/l/Progress.vue";

@Component({
  components: {
    LProgress,
  }
})
export default class Tab extends Vue {
  @Prop() title: string;
  @Prop({default: false}) need: boolean;
  @Prop({default: false}) loading: boolean;

  get is_input() {
    return this.is_edit;
  }

  @Inject({default: false})
  is_edit: boolean;

  @Inject({default: false})
  force_sp: boolean;
}
