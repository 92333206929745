
  import { Component, Mixins, Vue, Prop, Watch } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import * as util from "@/util";
  import LItemlevel from "@/components/l/Itemlevel.vue";
  import EmployeeeachLaborTaxinsRestaxzip from "@/components/employeeeach/labor/taxins/Restaxzip.vue";
  import EmployeeeachLaborTaxinsRestaxsum from "@/components/employeeeach/labor/taxins/Restaxsum.vue";
  import EmployeeeachLaborTaxinsRestaxmonthly from "@/components/employeeeach/labor/taxins/Restaxmonthly.vue";

  @Component({
    components: {
      LItemlevel,
      EmployeeeachLaborTaxinsRestaxzip,
      EmployeeeachLaborTaxinsRestaxsum,
      EmployeeeachLaborTaxinsRestaxmonthly,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    @Prop() year: number;

    restax_sum_edit = 0;

    @Watch("object")
    onChangeObject(object) {
      this.restax_sum_edit = 0;
      for (let i = 0; i < 12; i++) {
        if (object.resident_taxes[this.restax_date[i]]) {
          this.restax_sum_edit += Number(object.resident_taxes[this.restax_date[i]].money);
        }
      }

      if (Array.isArray(object.resident_tax_years)) {
        Vue.set(object, "resident_tax_years", {});
      }
      if (Array.isArray(object.resident_taxes)) {
        Vue.set(object, "resident_taxes", {});
      }

      // this.restax_sum_input(this.restax_sum_edit);

      if (!object.resident_tax_years[this.year]) {
        Vue.set(object.resident_tax_years, this.year, { zip: null });
      }
    }

    created() {
      this.onChangeObject(this.object);
    }

    get restax_date() {
      const date_array = [];
      let start_dateobj = util.date2obj(`${this.year}-06-01`);

      for (let i = 0; i < 12; i++) {
        date_array.push(util.formatDate(start_dateobj, "yyyy-mm"));
        start_dateobj = util.next_month(start_dateobj);
      }

      return date_array;
    }

    restax_sum_input(val) {
      const tmp = Math.floor(val / 1200) * 100;
      const first = val - tmp * 11;
      const other = tmp;

      Vue.set(this.object.resident_taxes, this.restax_date[0], { money: first });
      for (let i = 1; i < 12; i++) {
        Vue.set(this.object.resident_taxes, this.restax_date[i], { money: other });
      }
    }
    set_restax_sum() {
      this.restax_sum_edit = 0;
      for (let i = 0; i < 12; i++) {
        if (this.object.resident_taxes[this.restax_date[i]]) {
          this.restax_sum_edit += Number(this.object.resident_taxes[this.restax_date[i]].money);
        }
      }
    }
    restax_input(val, yearmonth) {
      Vue.set(this.object.resident_taxes, yearmonth, { money: val });
      this.set_restax_sum();
    }
  }
