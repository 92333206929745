
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
  components: {
  }
})
export default class Config extends Vue {
  @Prop({default: 'other'}) theme: string;
}
