
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'social_ins_id';
    this._popup_title = 'social_ins_id';
    this._popup_content = 'popup.social_ins_id';
  }

  get condition() {
    return this.object.health_ins_type == 1 || this.object.pension_ins_type == 1;
  }
}
