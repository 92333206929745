
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    LItem,
    FSelect,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Prop() health_ins_stdincome_hash: any;

  created() {
    this._title = 'std_income';
    this._need = true;
  }

  get condition() {
    return this.object.health_ins_type == 1 || this.object.pension_ins_type == 1;
  }
}
