
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
  components: {
  }
})
export default class Config extends Vue {
  @Prop() value: string;
  @Prop() this_value: string;
  @Prop() pages: string[];
  @Prop({default: false}) header: boolean;

  get is_active() {
    return this.pages.indexOf(this.value) !== -1;
  }
  active() {
    this.$emit('input', this.this_value);
  }
}
