
import { Component, Prop, Mixins } from 'vue-property-decorator';
import master_realtime from "@/vuex/master/realtime";
import validation from '@/library/validation';
import mixinForm from '@/mixins/mixinForm';
import { VTextField } from "vuetify/lib";


@Component({
  components: {
    VTextField,
  }
})
export default class Text extends Mixins(mixinForm) {
  @Prop() value: any;
  @Prop({default: ''}) placeholder: string;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: '100%'}) width: string;
  @Prop({default: '###-####'}) mask: string;
  @Prop() filter: undefined | ((val: string) => string);
  @Prop({default: true}) fetch_address: boolean;

  get disp_value() {
    if (this.filter === undefined) {
      return this.value;
    } else {
      return this.filter(this.value);
    }
  }
  get tmp_value() {
    return this.value;
  }
  set tmp_value(val) {
    this.$emit('input', val);
  }

  get style() {
    return {
      '--width': this.width,
    }
  }

  public async inputZip(zip) {
    if (this.fetch_address && validation.isset(zip) && validation.is_zip(zip)) {
      this.$emit('loading', true);
      const res = await master_realtime.readZip({zip});
      if (res !== null) {
        this.$emit('address', res);
      }
      this.$emit('loading', false);
    }
  }
}
