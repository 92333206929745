import { render, staticRenderFns } from "./Stdincome.vue?vue&type=template&id=49d6fa62&scoped=true&lang=pug"
import script from "./Stdincome.vue?vue&type=script&lang=ts"
export * from "./Stdincome.vue?vue&type=script&lang=ts"
import style0 from "./Stdincome.vue?vue&type=style&index=0&id=49d6fa62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d6fa62",
  null
  
)

export default component.exports