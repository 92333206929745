import { render, staticRenderFns } from "./Traininghourlywage.vue?vue&type=template&id=a0d13e38&scoped=true&lang=pug"
import script from "./Traininghourlywage.vue?vue&type=script&lang=ts"
export * from "./Traininghourlywage.vue?vue&type=script&lang=ts"
import style0 from "./Traininghourlywage.vue?vue&type=style&index=0&id=a0d13e38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0d13e38",
  null
  
)

export default component.exports