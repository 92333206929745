
  import { Component, Mixins, Vue, Prop } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import dialog from "@/vuex/dialog";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";
  import FCheck from "@/components/f/Check.vue";
  import FText from "@/components/f/Text.vue";
  import FSelect from "@/components/f/Select.vue";
  import FButton from "@/components/f/Button.vue";
  import FTextarea from "@/components/f/Textarea.vue";

  @Component({
    components: {
      LItem,
      FRadio,
      FCheck,
      FText,
      FSelect,
      FButton,
      FTextarea,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    @Prop() is_branchly_carfare: boolean;
    created() {
      this._title = "carfare";
      this._info = "info.carfare_way";
      this._need = true;
      this.auth_shift = false;
      this.auth_attendance = false;
      this.auth_nencho = false;
    }

    get carfare_keys() {
      return { 0: "is_train", 1: "is_car" };
    }

    get route_placeholder() {
      if (this.object.is_train && !this.object.is_car)
        return `${this.$t("route_placeholder_tojo")},　\n${this.$t(
          "route_placeholder_fukutoshin",
        )}`;
      else if (!this.object.is_train && this.object.is_car)
        return `${this.$t("route_placeholder_localroad")},　\n${this.$t(
          "route_placeholder_highway",
        )}`;
      else if (this.object.is_train && this.object.is_car)
        return `${this.$t("route_placeholder_bike")},　\n${this.$t(
          "route_placeholder_tojo",
        )},　\n${this.$t("route_placeholder_fukutoshin")}`;
    }

    add_carfare() {
      if (Array.isArray(this.object.carfares)) {
        Vue.set(this.object, "carfares", {});
      }
      Object.keys(this.object.belong_branch_hash).some((branch_id) => {
        if (this.object.carfares[branch_id] === undefined) {
          Vue.set(this.object.carfares, branch_id, { money: null });
          return true;
        }
      });
      console.log(this.object.carfares);
    }
    delete_carfare(branch_id) {
      dialog.openConfirmDialog({
        msg: `${this.object.belong_branch_hash[branch_id]}の例外通勤手当を削除してよろしいですか？`,
        cancel_button: "削除しない",
        exec_button: "削除する",
        func: this.process_delete_carfare,
        args: { branch_id },
      });
    }
    process_delete_carfare({ branch_id }) {
      Vue.delete(this.object.carfares, branch_id);
    }

    select_carfare(e, branch_id, val) {
      if (this.object.carfares[val] !== undefined) {
        e.target.value = branch_id;
        dialog.openAlertDialog({
          msg: `${this.object.belong_branch_hash[val]}の例外通勤手当は既に存在します`,
        });
      } else {
        Vue.set(this.object.carfares, val, this.object.carfares[branch_id]);
        Vue.delete(this.object.carfares, branch_id);
      }
    }
  }
