
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import * as util from '@/util';
import EmployeeeachLaborTaxinsRestax from "@/components/employeeeach/labor/taxins/Restax.vue";

@Component({
  components: {
    EmployeeeachLaborTaxinsRestax,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  get year() {
    const year = util.obj2year();
    const month = util.obj2month();
    
    if (month <= 5) {
      return year - 1; 
    } else {
      return year;
    } 
  }
}
