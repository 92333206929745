
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';
import FButton from "@/components/f/Button.vue";

@Component({
  components: {
    FButton,
  }
})
export default class Pager extends Mixins(mixinForm) {
  @Prop({default: 'normal'}) button_style: string;
  @Prop() is_editted: boolean;
  @Prop() name?: string;
  @Prop({default: 'edit'}) type: string;//add or edit
  @Prop({default: '140px'}) width: string

  cancel() {
    this.$emit('cancel');
  }
  exec() {
    this.$emit('exec');
  }
  start() {
    this.$emit('start');
  }
  erase() {
    this.$emit('delete');
  }

  get is_input() {
    return this.is_editted || (this.use_is_edit || this.use_is_add);
  }
}
