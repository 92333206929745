
  import { Component, Mixins } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";
  import FText from "@/components/f/Text.vue";

  @Component({
    components: {
      LItem,
      FRadio,
      FText,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    created() {
      this._title = "health_care_ins";
      this._need = true;
    }
  }
