
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() value: any;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: 'normal'}) background_color: string;
  @Prop({default: 'normal'}) border_color: string;
  @Prop({default: false}) is_option_vertical: boolean;
  
  get checked_value() {
    return this.value;
  }
  set checked_value(val) {
    this.$emit('input', val ? 1 : 0);
  }
}
