
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";
import FTime from "@/components/f/Time.vue";

@Component({
  components: {
    LItem,
    FRadio,
    FTime,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'month_prescribed_time';
    this._info = 'info.month_prescribed_time';
    this._popup_title = 'month_prescribed_time';
    this._popup_content = 'popup.month_prescribed_time';
    this.auth_shift = false;
    this.auth_nencho = false;
  }

  changeMonthPrescribedHourType(val) {
    if (val == 0) {
      this.object.month_prescribed_hour = null;
    }
  }
}
