
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FDate from "@/components/f/Date.vue";

@Component({
  components: {
    LItem,
    FDate,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'emp_ins_got_on';
    this._info = 'info.emp_ins_got_on';
  }

  get condition() {
    return this.object.emp_ins_type == 1;
  }
}
