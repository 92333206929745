
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinForm from "@/mixins/mixinForm";
  import FPartsCheck from "@/components/f/parts/Check.vue";

  @Component({
    components: {
      FPartsCheck,
    },
  })
  export default class Normal extends Mixins(mixinForm) {
    @Prop() value: any;
    @Prop() options?: { [id: number | string]: number | string };
    @Prop({ default: false }) multi: boolean;
    @Prop() this_value: number; //multiがtrueの場合
    @Prop() option?: string;
    @Prop() order?: number[];
    @Prop() disabled: boolean;
    @Prop({ default: false }) is_vertical: boolean;
    @Prop({ default: false }) is_option_vertical: boolean;
    @Prop({ default: false }) is_check_all: boolean;
    @Prop() filter: any;
    @Prop() object: any;
    @Prop() keys: any;

    get disp_value() {
      if (this.multi) {
        if (this.filter === undefined) {
          return this.value.indexOf(Number(this.this_value)) !== -1;
        } else {
          return this.filter(this.value.indexOf(Number(this.this_value)) !== -1);
        }
      } else if (this.options) {
        let str = "";
        Object.keys(this.keys).forEach((key) => {
          if (this.object[this.keys[key]]) {
            str += `${this.options[key]} `;
          }
        });
        if (this.filter === undefined) {
          return str;
        } else {
          return this.filter(str);
        }
      } else if (this.filter === undefined) {
        if (this.option) {
          if (this.value) {
            return this.option;
          } else {
            return "";
          }
        } else {
          return this.value;
        }
      } else {
        if (typeof this.filter == "object") {
          return this.filter[this.value];
        } else {
          return this.filter(this.value);
        }
      }
    }

    get tmp_value() {
      if (this.options) {
        const result = [];
        Object.keys(this.keys).forEach((key) => {
          if (this.object[this.keys[key]]) {
            result.push(Number(key));
          }
        });
        return result;
      } else if (this.multi) {
        return this.value.indexOf(Number(this.this_value)) !== -1;
      } else {
        return this.value;
      }
    }
    set tmp_value(val) {
      if (this.multi) {
        if (val) {
          const tmp = this.value;
          tmp.push(this.this_value);
          this.$emit("input", tmp);
        } else {
          this.$emit(
            "input",
            this.value.filter((value) => value != this.this_value),
          );
        }
      } else {
        this.$emit("input", val);
      }
    }
    setMultiValue(key, val) {
      this.object[this.keys[key]] = val;
      this.$emit("input", this.object);
      this.$emit("update", { key, val });
    }

    get use_options() {
      const options = [];
      if (this.order) {
        this.order.forEach((key) => {
          options.push({ key: Number(key), value: this.options[key] });
        });
      } else {
        Object.keys(this.options).forEach((key) => {
          options.push({ key: Number(key), value: this.options[key] });
        });
      }
      return options;
    }
  }
