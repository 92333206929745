import { render, staticRenderFns } from "./Socialinsgoton.vue?vue&type=template&id=654da929&scoped=true&lang=pug"
import script from "./Socialinsgoton.vue?vue&type=script&lang=ts"
export * from "./Socialinsgoton.vue?vue&type=script&lang=ts"
import style0 from "./Socialinsgoton.vue?vue&type=style&index=0&id=654da929&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "654da929",
  null
  
)

export default component.exports