
import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";
import FText from "@/components/f/Text.vue";
import FIcon from "@/components/f/Icon.vue";
import FCheck from "@/components/f/Check.vue";
import FTime from "@/components/f/Time.vue";

@Component({
  components: {
    LItem,
    FRadio,
    FText,
    FIcon,
    FCheck,
    FTime,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'training_hourly_wage';
    this.auth_shift = false;
    this.auth_attendance = false;
    this.auth_nencho = false;

    if (this.object.training_hourly_wages.length == 0) {
      this.object.training_hourly_wages.push({monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 1, sunday: 1, holiday: 1, each_training_hourly_wage: [{ended_at: 1440, money: null}]});
    }
  }

  get condition() {
    return this.object.wage_type == 0 && (this.is_edit || this.object.training_hourly_wage_type != 0);
  }

  getHourlyWageDay(branch_hourly_wage) {
    const result = [];
    if (branch_hourly_wage.sunday) result.push(0);
    if (branch_hourly_wage.monday) result.push(1);
    if (branch_hourly_wage.tuesday) result.push(2);
    if (branch_hourly_wage.wednesday) result.push(3);
    if (branch_hourly_wage.thursday) result.push(4);
    if (branch_hourly_wage.friday) result.push(5);
    if (branch_hourly_wage.saturday) result.push(6);
    if (branch_hourly_wage.holiday) result.push(7);
    return result;
  }

  get day_keys() {
    return {0: 'sunday', 1: 'monday', 2: 'tuesday', 3: 'wednesday', 4: 'thursday', 5: 'friday', 6: 'saturday', 7: 'holiday'};
  }

  change_training_hourly_wage_type(val) {
    if (val != 0) {
      if (this.object.training_hourly_wages.length == 0) {
        this.object.training_hourly_wages.push({monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 1, sunday: 1, holiday: 1, each_training_hourly_wage: [{ended_at: 1440, money: null}]});
      }
    }
  }

  check_training_hourly_wage_day(training_hourly_wage_index, val, day) {
    if (this.object.training_hourly_wages[training_hourly_wage_index] === undefined) {
      Vue.set(this.object.training_hourly_wages, training_hourly_wage_index, {monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 1, sunday: 1, holiday: 1, each_training_hourly_wage: [{ended_at: 1440, money: null}]});
    }
    Vue.set(this.object.training_hourly_wages[training_hourly_wage_index], day, val);

    if (val == 0) {
      //チェックを外した
      var target_training_hourly_wage = this.object.training_hourly_wages[training_hourly_wage_index];
      if (
        target_training_hourly_wage.monday == 0
        && target_training_hourly_wage.tuesday == 0
        && target_training_hourly_wage.wednesday == 0
        && target_training_hourly_wage.thursday == 0
        && target_training_hourly_wage.friday == 0
        && target_training_hourly_wage.saturday == 0
        && target_training_hourly_wage.sunday == 0
        && target_training_hourly_wage.holiday == 0
      ) {
        //全てチェックを外すことになった。 => 上にそれを入れる
        if (training_hourly_wage_index == 0) {
          //でもこれが一番上 => 次のやつに入れる
          Vue.set(this.object.training_hourly_wages[training_hourly_wage_index + 1], day, 1);
        } else {
          //上にやつに入れる
          Vue.set(this.object.training_hourly_wages[training_hourly_wage_index - 1], day, 1);
        }
      } else {
        //全てのチェックを外すわけではない => したに既にあればそれに入れる　ないなら新しく作る
        if (this.object.training_hourly_wages.length > training_hourly_wage_index + 1) {
          Vue.set(this.object.training_hourly_wages[training_hourly_wage_index + 1], day, 1);
        } else {
          const length = this.object.training_hourly_wages.push({monday: 0, tuesday: 0, wednesday: 0, thursday: 0, friday: 0, saturday: 0, sunday: 0, holiday: 0, each_training_hourly_wage: [{ended_at: 1440, money: null}]})
          Vue.set(this.object.training_hourly_wages[length - 1], day, 1);
        }
      }
    } else {
      //チェックを入れた => 他にチェックが入ってるやつを探してそれを消す
      var training_hourly_wages = [];
      this.object.training_hourly_wages.forEach((obj, index) => {
        if (index != training_hourly_wage_index && obj[day] == 1) {
          //今回セットしたやつではなく、その曜日が1になっている => それを0にする
          obj[day] = 0;
          //もしそれが全て0ではないなら容認する 全て0なら容認しない
          if (
            obj.monday == 1
            || obj.tuesday == 1
            || obj.wednesday == 1
            || obj.thursday == 1
            || obj.friday == 1
            || obj.saturday == 1
            || obj.sunday == 1
            || obj.holiday == 1
          ) {
            training_hourly_wages.push(obj);
          }
        } else {
          //特に何もないから容認
          training_hourly_wages.push(obj);
        }
      });
      Vue.set(this.object, 'training_hourly_wages', training_hourly_wages);
    }

    //何もないやつを削除
    var training_hourly_wages = [];
    this.object.training_hourly_wages.forEach((obj, index) => {
      //一つでも1なら入れる
      if (
        obj.monday == 1
        || obj.tuesday == 1
        || obj.wednesday == 1
        || obj.thursday == 1
        || obj.friday == 1
        || obj.saturday == 1
        || obj.sunday == 1
        || obj.holiday == 1
      ) {
        training_hourly_wages.push(obj);
      }
    });
    Vue.set(this.object, 'training_hourly_wages', training_hourly_wages);
  }

  input_training_hourly_wage_money(training_hourly_wage_index, each_training_hourly_wage_index, val) {
    if (this.object.training_hourly_wages[training_hourly_wage_index] === undefined) {
      Vue.set(this.object.training_hourly_wages, training_hourly_wage_index, {monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 1, sunday: 1, holiday: 1, each_training_hourly_wage: [{ended_at: 1440, money: null}]});
    }
    if (this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage[each_training_hourly_wage_index] === undefined) {
      Vue.set(this.object.training_hourly_wages[training_hourly_wage_index], each_training_hourly_wage_index, {ended_at: 1440, money: null});
    }
    Vue.set(this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage[each_training_hourly_wage_index], 'money', val);
  }

  input_training_hourly_wage_time(training_hourly_wage_index, each_training_hourly_wage_index, val) {
    if (this.object.training_hourly_wages[training_hourly_wage_index] === undefined) {
      Vue.set(this.object.training_hourly_wages, training_hourly_wage_index, {monday: 1, tuesday: 1, wednesday: 1, thursday: 1, friday: 1, saturday: 1, sunday: 1, holiday: 1, each_training_hourly_wage: [{ended_at: 1440, money: null}]});
    }
    if (this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage[each_training_hourly_wage_index] === undefined) {
      Vue.set(this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage, each_training_hourly_wage_index, {ended_at: 1440, money: null});
    }
    Vue.set(this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage[each_training_hourly_wage_index], 'ended_at', val);

    if (val == 1440) {
      this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage.splice(each_training_hourly_wage_index + 1);
    } else {
      if (each_training_hourly_wage_index == this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage.length - 1) {
        Vue.set(this.object.training_hourly_wages[training_hourly_wage_index].each_training_hourly_wage, each_training_hourly_wage_index + 1, {ended_at: 1440, money: null});
      }
    }
  }
}
