import { Component, Mixins, Prop } from "vue-property-decorator";
import mixinPart from "./mixinPart";
import { Inject } from "@/library/provide";

@Component
export default class mixinEmployeeEachPart extends Mixins(mixinPart) {
  @Inject()
  employee_type: number;

  auth_shift: boolean = true;
  auth_attendance: boolean = true;
  auth_payment: boolean = true;
  auth_nencho: boolean = true;

  get employee_type_auth() {
    const type_array = [
      { shift: true, attendance: true, payment: true, nencho: true },
      { shift: true, attendance: false, payment: false, nencho: false },
      { shift: true, attendance: true, payment: false, nencho: false },
      { shift: false, attendance: false, payment: true, nencho: false },
      { shift: false, attendance: false, payment: true, nencho: true },
      { shift: false, attendance: false, payment: false, nencho: true },
    ];
    return type_array[this.employee_type];
  }

  get condition() {
    return true; //override
  }

  get is_disp() {
    return (
      this.condition &&
      ((this.auth_shift && this.employee_type_auth.shift) ||
        (this.auth_attendance && this.employee_type_auth.attendance) ||
        (this.auth_payment && this.employee_type_auth.payment) ||
        (this.auth_nencho && this.employee_type_auth.nencho))
    );
  }
}
