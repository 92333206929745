
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';

@Component({
  components: {
  }
})
export default class Textarea extends Mixins(mixinForm) {
  @Prop() value: any;
  @Prop({default: 5}) rows: number;
  @Prop({default: ''}) placeholder: string;
  @Prop() width: string;
  @Prop() filter: undefined | ((val: string) => string);

  get disp_value() {
    if (this.filter === undefined) {
      return this.value;
    } else {
      return this.filter(this.value);
    }
  }
  get tmp_value() {
    return this.value;
  }
  set tmp_value(val) {
    this.$emit('input', val == '' ? null : val);
  }

  get style() {
    return {
      '--width': this.width,
    }
  }
}
