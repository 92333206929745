
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import LItem from "@/components/l/Item.vue";
  import FText from "@/components/f/Text.vue";

  @Component({
    components: {
      LItem,
      FText,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    @Prop() value: number | null;

    input(val) {
      this.$emit("input", val);
    }

    created() {
      this._title = "year_money";
      this._info = "info.restax_sum";
    }
  }
