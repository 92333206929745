
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FTime from "@/components/f/Time.vue";

@Component({
  components: {
    LItem,
    FTime,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'week_prescribed_time';
    this._info = 'info.week_prescribed_time';
    this._popup_title = 'week_prescribed_time';
    this._popup_content = 'popup.week_prescribed_time';
    this.auth_shift = false;
    this.auth_nencho = false;
  }
}
