
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FZip from "@/components/f/Zip.vue";

@Component({
  components: {
    LItem,
    FZip,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Prop() year: number;

  created() {
  }
}
