
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FToggle from "@/components/f/Toggle.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FToggle,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'double_biz';
    this._need = true;
  }

  get condition() {
    return (this.is_edit || this.object.is_double_biz == 1) && this.object.health_ins_type == 1 || this.object.pension_ins_type == 1;
  }
}
