
import { Component, Prop, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import { VExpansionPanel } from "vuetify/lib";
import { VExpansionPanelContent } from "vuetify/lib";
import FPartsSidemenu from "@/components/f/parts/Sidemenu.vue";

@Component({
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    FPartsSidemenu,
  }
})
export default class Normal extends Mixins(utilMixins) {
  @Prop() options: {[id: string]: {name: string, route: string, pages: string[]}};
  @Prop() sub_options?: {[id: string]: {name: string, route: string, pages: string[]}[]};//サブ項目がある場合(タブを全てサイドメニューに押し込む場合)
  @Prop() initial: string;

  value: string | null = null

  mounted() {
    window.addEventListener("scroll", this.setValue);
    this.value = this.initial;
  }

  setValue() {
    var result = null;
    Object.keys(this.options).forEach(key => {
      this.options[key].pages.forEach(page => {
        const element_offset = $('#'+page).offset();
        if (element_offset) {
          if (window.scrollY > element_offset.top - 205) {
            result = page;
            return;
          }
        }
      });
      if (result) {
        return;
      }
    });
    if (result) {
      this.value = result;
    }
  }


  get expand() {
    var expand = {};
    Object.keys(this.options).forEach(key => {
      expand[key] = null;
    })
    expand[this.getKey(this.value)] = 0;
    return expand;
  }

  getKey(page) {
    var result = null;
    Object.keys(this.options).forEach(key => {
      if (this.options[key].pages.indexOf(page) !== -1) {
        result = key
      }
    });
    return result;
  }



  input(page) {
    const element_offset = $('#'+page).offset();
    if (!element_offset) return;
    $("html, body").animate({scrollTop:　element_offset.top - 200}, 100, "linear");
  }
}
