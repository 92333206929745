
  import { Component, Mixins, Watch } from "vue-property-decorator";
  import * as util from "@/util";
  import { Provide } from "@/library/provide";
  import mixinEmployeeAdmit from "@/mixins/mixinEmployeeAdmit";
  import validation from "@/library/validation";
  import LProgress from "@/components/l/Progress.vue";
  import LPage from "@/components/l/Page.vue";
  import LBread from "@/components/l/Bread.vue";
  import LSidemenu from "@/components/l/Sidemenu.vue";
  import FSidemenuscroll from "@/components/f/Sidemenuscroll.vue";
  import LContent from "@/components/l/Content.vue";
  import EmployeeeachSelfBaseCode from "@/components/employeeeach/self/base/Code.vue";
  import EmployeeeachSelfBaseAuth from "@/components/employeeeach/self/base/Auth.vue";
  import EmployeeeachSelfBaseHiringtype from "@/components/employeeeach/self/base/Hiringtype.vue";
  import EmployeeeachSelfBaseMainbranch from "@/components/employeeeach/self/base/Mainbranch.vue";
  import EmployeeeachSelfBasePost from "@/components/employeeeach/self/base/Post.vue";
  import EmployeeeachSelfBaseEmployeetype from "@/components/employeeeach/self/base/Employeetype.vue";
  import EmployeeeachLaborGeneralWorktype from "@/components/employeeeach/labor/general/Worktype.vue";
  import EmployeeeachLaborGeneralCoretime from "@/components/employeeeach/labor/general/Coretime.vue";
  import LItemlevel from "@/components/l/Itemlevel.vue";
  import EmployeeeachLaborGeneralWeekprescribedday from "@/components/employeeeach/labor/general/Weekprescribedday.vue";
  import EmployeeeachLaborGeneralWeekprescribedtime from "@/components/employeeeach/labor/general/Weekprescribedtime.vue";
  import EmployeeeachLaborGeneralMonthprescribedday from "@/components/employeeeach/labor/general/Monthprescribedday.vue";
  import EmployeeeachLaborGeneralMonthprescribedtime from "@/components/employeeeach/labor/general/Monthprescribedtime.vue";
  import EmployeeeachLaborGeneralJoinedon from "@/components/employeeeach/labor/general/Joinedon.vue";
  import EmployeeeachLaborGeneralContractstartedon from "@/components/employeeeach/labor/general/Contractstartedon.vue";
  import EmployeeeachLaborGeneralJob from "@/components/employeeeach/labor/general/Job.vue";
  import EmployeeeachLaborGeneralMemo from "@/components/employeeeach/labor/general/Memo.vue";
  import EmployeeeachLaborPaymentBasewage from "@/components/employeeeach/labor/payment/Basewage.vue";
  import EmployeeeachLaborPaymentTraininghourlywage from "@/components/employeeeach/labor/payment/Traininghourlywage.vue";
  import EmployeeeachLaborPaymentBasepay from "@/components/employeeeach/labor/payment/Basepay.vue";
  import EmployeeeachLaborPaymentIndividualunit from "@/components/employeeeach/labor/payment/Individualunit.vue";
  import EmployeeeachLaborPaymentCarfare from "@/components/employeeeach/labor/payment/Carfare.vue";
  import EmployeeeachLaborPaymentOvertype from "@/components/employeeeach/labor/payment/Overtype.vue";
  import EmployeeeachLaborPaymentIsmid from "@/components/employeeeach/labor/payment/Ismid.vue";
  import EmployeeeachLaborPaymentIshol from "@/components/employeeeach/labor/payment/Ishol.vue";
  import EmployeeeachLaborPaymentUsepaidholtype from "@/components/employeeeach/labor/payment/Usepaidholtype.vue";
  import EmployeeeachLaborPaymentIsbonus from "@/components/employeeeach/labor/payment/Isbonus.vue";
  import EmployeeeachLaborTaxinsIsincometax from "@/components/employeeeach/labor/taxins/Isincometax.vue";
  import EmployeeeachLaborTaxinsIncometaxcomment from "@/components/employeeeach/labor/taxins/Incometaxcomment.vue";
  import EmployeeeachLaborTaxinsRestax1 from "@/components/employeeeach/labor/taxins/Restax1.vue";
  import EmployeeeachLaborTaxinsRestax2 from "@/components/employeeeach/labor/taxins/Restax2.vue";
  import EmployeeeachLaborTaxinsHealthcareins from "@/components/employeeeach/labor/taxins/Healthcareins.vue";
  import EmployeeeachLaborTaxinsPensionins from "@/components/employeeeach/labor/taxins/Pensionins.vue";
  import EmployeeeachLaborTaxinsChildcare from "@/components/employeeeach/labor/taxins/Childcare.vue";
  import EmployeeeachLaborTaxinsStdincome from "@/components/employeeeach/labor/taxins/Stdincome.vue";
  import EmployeeeachLaborTaxinsDoublebiz from "@/components/employeeeach/labor/taxins/Doublebiz.vue";
  import EmployeeeachLaborTaxinsPensioninsid from "@/components/employeeeach/labor/taxins/Pensioninsid.vue";
  import EmployeeeachLaborTaxinsSocialinsid from "@/components/employeeeach/labor/taxins/Socialinsid.vue";
  import EmployeeeachLaborTaxinsSocialinsgoton from "@/components/employeeeach/labor/taxins/Socialinsgoton.vue";
  import EmployeeeachLaborTaxinsEmpins from "@/components/employeeeach/labor/taxins/Empins.vue";
  import EmployeeeachLaborTaxinsEmpinsid from "@/components/employeeeach/labor/taxins/Empinsid.vue";
  import EmployeeeachLaborTaxinsEmpinsgoton from "@/components/employeeeach/labor/taxins/Empinsgoton.vue";
  import EmployeeeachLaborTaxinsCompins from "@/components/employeeeach/labor/taxins/Compins.vue";
  import EmployeeeachLaborTaxinsCompinsgoton from "@/components/employeeeach/labor/taxins/Compinsgoton.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LProgress,
      LPage,
      LBread,
      LSidemenu,
      FSidemenuscroll,
      LContent,
      EmployeeeachSelfBaseCode,
      EmployeeeachSelfBaseAuth,
      EmployeeeachSelfBaseHiringtype,
      EmployeeeachSelfBaseMainbranch,
      EmployeeeachSelfBasePost,
      EmployeeeachSelfBaseEmployeetype,
      EmployeeeachLaborGeneralWorktype,
      EmployeeeachLaborGeneralCoretime,
      LItemlevel,
      EmployeeeachLaborGeneralWeekprescribedday,
      EmployeeeachLaborGeneralWeekprescribedtime,
      EmployeeeachLaborGeneralMonthprescribedday,
      EmployeeeachLaborGeneralMonthprescribedtime,
      EmployeeeachLaborGeneralJoinedon,
      EmployeeeachLaborGeneralContractstartedon,
      EmployeeeachLaborGeneralJob,
      EmployeeeachLaborGeneralMemo,
      EmployeeeachLaborPaymentBasewage,
      EmployeeeachLaborPaymentTraininghourlywage,
      EmployeeeachLaborPaymentBasepay,
      EmployeeeachLaborPaymentIndividualunit,
      EmployeeeachLaborPaymentCarfare,
      EmployeeeachLaborPaymentOvertype,
      EmployeeeachLaborPaymentIsmid,
      EmployeeeachLaborPaymentIshol,
      EmployeeeachLaborPaymentUsepaidholtype,
      EmployeeeachLaborPaymentIsbonus,
      EmployeeeachLaborTaxinsIsincometax,
      EmployeeeachLaborTaxinsIncometaxcomment,
      EmployeeeachLaborTaxinsRestax1,
      EmployeeeachLaborTaxinsRestax2,
      EmployeeeachLaborTaxinsHealthcareins,
      EmployeeeachLaborTaxinsPensionins,
      EmployeeeachLaborTaxinsChildcare,
      EmployeeeachLaborTaxinsStdincome,
      EmployeeeachLaborTaxinsDoublebiz,
      EmployeeeachLaborTaxinsPensioninsid,
      EmployeeeachLaborTaxinsSocialinsid,
      EmployeeeachLaborTaxinsSocialinsgoton,
      EmployeeeachLaborTaxinsEmpins,
      EmployeeeachLaborTaxinsEmpinsid,
      EmployeeeachLaborTaxinsEmpinsgoton,
      EmployeeeachLaborTaxinsCompins,
      EmployeeeachLaborTaxinsCompinsgoton,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinEmployeeAdmit) {
    created() {
      this.m.initEditManager({ name: "admit" });
      if (this.fetched) {
        this.fetchedData(this.fetched);
      }
    }

    @Watch("employee_type")
    onChangeEmployeeType(newval, oldval) {
      if (newval == 1) {
        //シフトのみに変わった
        this.object.birth_date = this.object.birth_date ?? "1990-01-01";
        this.object.zip =
          this.object.zip !== null && validation.is_zip(this.object.zip)
            ? this.object.zip
            : "000-0000";
        this.object.address = this.object.address ?? "不明";

        this.object.tax_type = 1;
        this.object.is_bank1 = 0;
        this.object.is_bank2 = 0;

        this.object.wage_type = 2;
        this.object.basepay = 0;
        this.object.carfare_type = 0;
        this.object.is_bonus = 0;
        this.object.over_type = 0;
        this.object.is_mid = 0;
        this.object.is_hol = 0;
        this.object.buy_paid_hol_unit = 0;

        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 0;
      } else if (newval == 2) {
        //シフトと勤怠に変わった
        this.object.birth_date = this.object.birth_date ?? "1990-01-01";
        this.object.zip =
          this.object.zip !== null && validation.is_zip(this.object.zip)
            ? this.object.zip
            : "000-0000";
        this.object.address = this.object.address ?? "不明";

        this.object.tax_type = 1;
        this.object.is_bank1 = 0;
        this.object.is_bank2 = 0;

        this.object.wage_type = 2;
        this.object.basepay = 0;
        this.object.coretime_type = 0;
        this.object.carfare_type = 0;
        this.object.is_bonus = 0;
        this.object.buy_paid_hol_unit = 0;

        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 0;
      } else if (newval == 3 || newval == 4) {
        this.object.coretime_type = 0;
      } else if (newval == 5) {
        //年末調整のみに変わった
        this.object.is_bank1 = 0;
        this.object.is_bank2 = 0;

        this.object.wage_type = 2;
        this.object.basepay = 0;
        this.object.work_type = 0;
        this.object.coretime_type = 0;
        this.object.carfare_type = 0;
        this.object.is_bonus = 0;
        this.object.over_type = 0;
        this.object.is_mid = 0;
        this.object.is_hol = 0;
        this.object.buy_paid_hol_unit = 0;

        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 0;

        //年末調整のみの場合、入社年月日はnullに
        this.object.joined_on = null;
        this.object.contract_started_on = null;
      }
      this.object.use_paid_hol_type = 1;
      this.object.joined_on = this.object.joined_on ?? util.today();
      this.object.contract_started_on = this.object.contract_started_on ?? util.today();
    }

    onChangeHiringType() {
      //開いた時のみ適用
      if (this.object.hiring_type == 0 || this.object.hiring_type == 3) {
        //アルバイト・その他
        this.object.tax_type = 1;
        this.object.wage_type = 0;
        this.object.carfare_type = this.object.carfare_type == 1 ? 1 : 0;
        this.object.is_bonus = 0;
        this.object.over_type = 1;
        this.object.is_preover = 1;
        this.object.is_lawover = 1;
        this.object.is_mid = 1;
        this.object.is_hol = 1;
        this.object.is_outhol = 1;
        this.object.is_lawhol = 1;
        this.object.health_ins_type = 0;
        this.object.pension_ins_type = 0;
        this.object.emp_ins_type = 0;
        this.object.is_comp_ins = 1;
        this.object.week_prescribed_day = 2;
        this.object.week_prescribed_hour = 480;
      } else if (this.object.hiring_type == 1) {
        //社員
        this.object.tax_type = 0;
        this.object.wage_type = 1;
        this.object.carfare_type = this.object.carfare_type == 1 ? 2 : 0;
        this.object.is_bonus = 1;
        this.object.over_type = 1;
        this.object.is_preover = 1;
        this.object.is_lawover = 1;
        this.object.is_mid = 1;
        this.object.is_hol = 1;
        this.object.is_outhol = 1;
        this.object.is_lawhol = 1;
        this.object.health_ins_type = 1;
        this.object.pension_ins_type = 1;
        this.object.emp_ins_type = 1;
        this.object.is_comp_ins = 1;
        this.object.week_prescribed_day = 5;
        this.object.week_prescribed_hour = 2400;
      } else if (this.object.hiring_type == 2) {
        //役員
        this.object.tax_type = 0;
        this.object.wage_type = 1;
        this.object.carfare_type = this.object.carfare_type == 1 ? 2 : 0;
        this.object.is_bonus = 0;
        this.object.over_type = 0;
        this.object.is_mid = 0;
        this.object.is_hol = 0;
        this.object.health_ins_type = 1;
        this.object.pension_ins_type = 1;
        this.object.emp_ins_type = 1;
        this.object.is_comp_ins = 1;
        this.object.week_prescribed_day = null;
        this.object.week_prescribed_hour = null;
      }
    }

    @Watch("fetched")
    fetchedData(fetched) {
      if (fetched) {
        this.m.edit_manager.admit.edit({ employee: this.employeely.employee });
        this.onChangeHiringType();
        this.onChangeEmployeeType(this.employeely.employee.type, 0);
      }
    }

    get fetched() {
      return !!this.global && !!this.employeely;
    }

    @Provide("is_edit")
    get is_edit() {
      return !!this.m.edit_manager.admit.flag;
    }

    @Provide("employee_type")
    get employee_type() {
      return this.object.type;
    }

    update() {
      this.m.update({
        name: "admit",
        args: { employee: this.object, employee_id: this.object.id },
        self: this,
      });
    }
    cancel() {
      this.change_page("employee", { employee_tab: "join" });
    }
  }
