
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FRadio,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'base_pay';
    this._need = true;
    this.auth_shift = false;
    this.auth_attendance = false;
    this.auth_nencho = false;
  }

  get condition() {
    return !this.is_edit || this.object.wage_type != 0;
  }
}
