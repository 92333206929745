
  import { Component, Mixins, Vue, Prop } from "vue-property-decorator";
  import mixinEmployeeEachPart from "@/mixins/mixinEmployeeEachPart";
  import dialog from "@/vuex/dialog";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";
  import FSelect from "@/components/f/Select.vue";
  import FButton from "@/components/f/Button.vue";
  import FIcon from "@/components/f/Icon.vue";
  import FCheck from "@/components/f/Check.vue";
  import FTime from "@/components/f/Time.vue";
  import FText from "@/components/f/Text.vue";

  @Component({
    components: {
      LItem,
      FRadio,
      FSelect,
      FButton,
      FIcon,
      FCheck,
      FTime,
      FText,
    },
  })
  export default class Main extends Mixins(mixinEmployeeEachPart) {
    @Prop() is_branch_hourly_wage: boolean;
    //  :isvalid="[page_type != 'add', !(page_type == 'join_admit' || page_type == 'demand_admit')]" :disable_reason="[[$t('usable_after_add_employee')], [$t('usable_after_admit_employee')]]"
    created() {
      this._title = "base_wage";
      this.auth_shift = false;
      this.auth_attendance = false;
      this.auth_nencho = false;
      this._need = true;

      if (this.object.hourly_wages[0] === undefined) {
        Vue.set(this.object.hourly_wages, 0, [
          {
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 1,
            friday: 1,
            saturday: 1,
            sunday: 1,
            holiday: 1,
            each_hourly_wage: [{ ended_at: 1440, money: null }],
          },
        ]);
      }
    }

    getHourlyWageDay(branch_hourly_wage) {
      const result = [];
      if (branch_hourly_wage.sunday) result.push(0);
      if (branch_hourly_wage.monday) result.push(1);
      if (branch_hourly_wage.tuesday) result.push(2);
      if (branch_hourly_wage.wednesday) result.push(3);
      if (branch_hourly_wage.thursday) result.push(4);
      if (branch_hourly_wage.friday) result.push(5);
      if (branch_hourly_wage.saturday) result.push(6);
      if (branch_hourly_wage.holiday) result.push(7);
      return result;
    }

    get day_keys() {
      return {
        0: "sunday",
        1: "monday",
        2: "tuesday",
        3: "wednesday",
        4: "thursday",
        5: "friday",
        6: "saturday",
        7: "holiday",
      };
    }

    add_hourly_wage() {
      this.object.hourly_wages = Object.assign({}, this.object.hourly_wages);
      Object.keys(this.object.belong_branch_hash).some((branch_id) => {
        if (this.object.hourly_wages[branch_id] === undefined) {
          Vue.set(this.object.hourly_wages, branch_id, [
            {
              monday: 1,
              tuesday: 1,
              wednesday: 1,
              thursday: 1,
              friday: 1,
              saturday: 1,
              sunday: 1,
              holiday: 1,
              each_hourly_wage: [{ ended_at: 1440, money: null }],
            },
          ]);
          return true;
        }
      });
      console.log(this.object.hourly_wages);
    }

    change_wage_type(val) {
      if (val == 0) {
        if (this.object.hourly_wages[0] === undefined) {
          Vue.set(this.object.hourly_wages, 0, [
            {
              monday: 1,
              tuesday: 1,
              wednesday: 1,
              thursday: 1,
              friday: 1,
              saturday: 1,
              sunday: 1,
              holiday: 1,
              each_hourly_wage: [{ ended_at: 1440, money: null }],
            },
          ]);
        }
        this.change_training_hourly_wage_type(this.object.training_hourly_wage_type);
      }
    }

    change_training_hourly_wage_type(val) {
      if (val != 0) {
        if (this.object.training_hourly_wages.length == 0) {
          this.object.training_hourly_wages.push({
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 1,
            friday: 1,
            saturday: 1,
            sunday: 1,
            holiday: 1,
            each_training_hourly_wage: [{ ended_at: 1440, money: null }],
          });
        }
      }
    }

    check_over(val) {
      if (!this.object.is_preover && !this.object.is_lawover) {
        this.object.over_type = 0;
      }
    }
    check_overtype() {
      if (this.object.over_type != 0) {
        if (!this.object.is_preover && !this.object.is_lawover) {
          this.object.is_preover = 1;
          this.object.is_lawover = 1;
        }
      }
    }
    check_hol() {
      if (!this.object.is_outhol && !this.object.is_lawhol) {
        this.object.is_hol = 0;
      }
    }
    check_is_hol() {
      if (this.object.is_hol != 0) {
        if (!this.object.is_outhol && !this.object.is_lawhol) {
          this.object.is_outhol = 1;
          this.object.is_lawhol = 1;
        }
      }
    }

    delete_hourly_wage(branch_id) {
      dialog.openConfirmDialog({
        msg: `${this.object.belong_branch_hash[branch_id]}の例外時給を削除してよろしいですか？`,
        cancel_button: "削除しない",
        exec_button: "削除する",
        func: this.process_delete_hourly_wage,
        args: { branch_id },
      });
    }
    process_delete_hourly_wage({ branch_id }) {
      Vue.delete(this.object.hourly_wages, branch_id);
    }

    select_hourly_wage(e, branch_id, val) {
      if (this.object.hourly_wages[val] !== undefined) {
        e.target.value = branch_id;
        dialog.openAlertDialog({
          msg: `${this.object.belong_branch_hash[val]}の例外時給は既に存在します`,
        });
      } else {
        Vue.set(this.object.hourly_wages, val, this.object.hourly_wages[branch_id]);
        Vue.delete(this.object.hourly_wages, branch_id);
      }
    }

    check_hourly_wage_day(branch_id, hourly_wage_index, val, day) {
      if (this.object.hourly_wages[branch_id] === undefined) {
        Vue.set(this.object.hourly_wages, branch_id, []);
      }
      if (this.object.hourly_wages[branch_id][hourly_wage_index] === undefined) {
        Vue.set(this.object.hourly_wages[branch_id], hourly_wage_index, {
          monday: 1,
          tuesday: 1,
          wednesday: 1,
          thursday: 1,
          friday: 1,
          saturday: 1,
          sunday: 1,
          holiday: 1,
          each_hourly_wage: [{ ended_at: 1440, money: null }],
        });
      }
      Vue.set(this.object.hourly_wages[branch_id][hourly_wage_index], day, val);

      if (val == 0) {
        //チェックを外した
        const target_hourly_wage = this.object.hourly_wages[branch_id][hourly_wage_index];
        if (
          target_hourly_wage.monday == 0 &&
          target_hourly_wage.tuesday == 0 &&
          target_hourly_wage.wednesday == 0 &&
          target_hourly_wage.thursday == 0 &&
          target_hourly_wage.friday == 0 &&
          target_hourly_wage.saturday == 0 &&
          target_hourly_wage.sunday == 0 &&
          target_hourly_wage.holiday == 0
        ) {
          //全てチェックを外すことになった。 => 上にそれを入れる
          if (hourly_wage_index == 0) {
            //でもこれが一番上 => 次のやつに入れる
            Vue.set(this.object.hourly_wages[branch_id][hourly_wage_index + 1], day, 1);
          } else {
            //上にやつに入れる
            Vue.set(this.object.hourly_wages[branch_id][hourly_wage_index - 1], day, 1);
          }
        } else {
          //全てのチェックを外すわけではない => したに既にあればそれに入れる　ないなら新しく作る
          if (this.object.hourly_wages[branch_id].length > hourly_wage_index + 1) {
            Vue.set(this.object.hourly_wages[branch_id][hourly_wage_index + 1], day, 1);
          } else {
            const length = this.object.hourly_wages[branch_id].push({
              id: null,
              employee_id: null,
              branch_id: null,
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thursday: 0,
              friday: 0,
              saturday: 0,
              sunday: 0,
              holiday: 0,
              each_hourly_wage: [{ ended_at: 1440, money: null }],
            });
            Vue.set(this.object.hourly_wages[branch_id][length - 1], day, 1);
          }
        }
      } else {
        //チェックを入れた => 他にチェックが入ってるやつを探してそれを消す
        var hourly_wages = [];
        this.object.hourly_wages[branch_id].forEach((obj, index) => {
          if (index != hourly_wage_index && obj[day] == 1) {
            //今回セットしたやつではなく、その曜日が1になっている => それを0にする
            obj[day] = 0;
            //もしそれが全て0ではないなら容認する 全て0なら容認しない
            if (
              obj.monday == 1 ||
              obj.tuesday == 1 ||
              obj.wednesday == 1 ||
              obj.thursday == 1 ||
              obj.friday == 1 ||
              obj.saturday == 1 ||
              obj.sunday == 1 ||
              obj.holiday == 1
            ) {
              hourly_wages.push(obj);
            }
          } else {
            //特に何もないから容認
            hourly_wages.push(obj);
          }
        });
        Vue.set(this.object.hourly_wages, branch_id, hourly_wages);
      }

      //何もないやつを削除
      var hourly_wages = [];
      this.object.hourly_wages[branch_id].forEach((obj, index) => {
        //一つでも1なら入れる
        if (
          obj.monday == 1 ||
          obj.tuesday == 1 ||
          obj.wednesday == 1 ||
          obj.thursday == 1 ||
          obj.friday == 1 ||
          obj.saturday == 1 ||
          obj.sunday == 1 ||
          obj.holiday == 1
        ) {
          hourly_wages.push(obj);
        }
      });
      Vue.set(this.object.hourly_wages, branch_id, hourly_wages);
    }

    input_hourly_wage_money(branch_id, hourly_wage_index, each_hourly_wage_index, val) {
      if (this.object.hourly_wages[branch_id] === undefined) {
        Vue.set(this.object.hourly_wages, branch_id, []);
      }
      if (this.object.hourly_wages[branch_id][hourly_wage_index] === undefined) {
        Vue.set(this.object.hourly_wages[branch_id], hourly_wage_index, {
          monday: 1,
          tuesday: 1,
          wednesday: 1,
          thursday: 1,
          friday: 1,
          saturday: 1,
          sunday: 1,
          holiday: 1,
          each_hourly_wage: [{ ended_at: 1440, money: null }],
        });
      }
      if (
        this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage[
          each_hourly_wage_index
        ] === undefined
      ) {
        Vue.set(this.object.hourly_wages[branch_id][hourly_wage_index], each_hourly_wage_index, {
          ended_at: 1440,
          money: null,
        });
      }
      Vue.set(
        this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage[
          each_hourly_wage_index
        ],
        "money",
        val,
      );
    }

    input_hourly_wage_time(branch_id, hourly_wage_index, each_hourly_wage_index, val) {
      if (this.object.hourly_wages[branch_id] === undefined) {
        Vue.set(this.object.hourly_wages, branch_id, []);
      }
      if (this.object.hourly_wages[branch_id][hourly_wage_index] === undefined) {
        Vue.set(this.object.hourly_wages[branch_id], hourly_wage_index, {
          monday: 1,
          tuesday: 1,
          wednesday: 1,
          thursday: 1,
          friday: 1,
          saturday: 1,
          sunday: 1,
          holiday: 1,
          each_hourly_wage: [{ ended_at: 1440, money: null }],
        });
      }
      if (
        this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage[
          each_hourly_wage_index
        ] === undefined
      ) {
        Vue.set(
          this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage,
          each_hourly_wage_index,
          { ended_at: 1440, money: null },
        );
      }
      Vue.set(
        this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage[
          each_hourly_wage_index
        ],
        "ended_at",
        val,
      );

      if (val == 1440) {
        this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage.splice(
          each_hourly_wage_index + 1,
        );
      } else {
        if (
          each_hourly_wage_index ==
          this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage.length - 1
        ) {
          Vue.set(
            this.object.hourly_wages[branch_id][hourly_wage_index].each_hourly_wage,
            each_hourly_wage_index + 1,
            { ended_at: 1440, money: null },
          );
        }
      }
    }

    daystr(hourly_wage) {
      let str = "";
      if (hourly_wage.monday == 1) str += "月";
      if (hourly_wage.tuesday == 1) str += "火";
      if (hourly_wage.wednesday == 1) str += "水";
      if (hourly_wage.thursday == 1) str += "木";
      if (hourly_wage.friday == 1) str += "金";
      if (hourly_wage.saturday == 1) str += "土";
      if (hourly_wage.sunday == 1) str += "日";
      if (hourly_wage.holiday == 1) str += "祝";
      if (str == "月火水木金土日祝") return null;
      else return str;
    }
  }
