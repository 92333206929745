
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Prop() restax_date: string[];

  created() {
    this._title = 'month_money';
  }
  
  restax_input(val, yearmonth) {
    this.$emit('input', {yearmonth, val});
  }
}
