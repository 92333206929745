
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";
import FCheck from "@/components/f/Check.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FRadio,
    FCheck,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'over_allowance';
    this._need = true;
    this.auth_shift = false;
    this.auth_nencho = false;
  }

  check_over(val) {
    if (!this.object.is_preover && !this.object.is_lawover) {
      this.object.over_type = 0;
    }
  }
  check_overtype() {
    if (this.object.over_type != 0) {
      if (!this.object.is_preover && !this.object.is_lawover) {
        this.object.is_preover = 1;
        this.object.is_lawover = 1;
      }
    }
  }
}
