
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import { Inject } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FToggle from "@/components/f/Toggle.vue";
import FTime from "@/components/f/Time.vue";

@Component({
  components: {
    LItem,
    FToggle,
    FTime,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'core_time';
    this._need = true;

    this.auth_attendance = false;
    this.auth_payment = false;
    this.auth_nencho = false;
  }

  get condition() {
    return this.object.work_type == 4;
  }
}
