
import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  @Prop() individual_units: any;
  @Prop() is_individual_unit: boolean;

  created() {
    this._title = 'individual_unit';
    this.auth_shift = false;
    this.auth_attendance = false;
    this.auth_nencho = false;
  }

  get condition() {
    return this.is_individual_unit;
  }

}
