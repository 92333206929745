
  import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import $ from "jquery";
  import validation from "@/library/validation";
  import mixinForm from "@/mixins/mixinForm";
  import { Provide } from "@/library/provide";
  import FSelect from "@/components/f/Select.vue";
  import FText from "@/components/f/Text.vue";

  @Component({
    components: {
      FSelect,
      FText,
    },
  })
  export default class Time extends Mixins(mixinForm) {
    @Prop() value: number | null;
    @Prop({ default: ":" }) hour_label;
    @Prop() minute_label;
    @Prop({ default: "30px" }) hour_width: string;
    @Prop({ default: "30px" }) minute_width: string;
    @Prop({ default: false }) disp_yesterday: boolean;
    @Prop({ default: true }) normal_time: boolean;
    @Prop() hour_select: number;
    @Prop({ default: 0 }) min_hour_select: number;
    @Prop({ default: 23 }) max_hour_select: number;
    @Prop() minute_select: number;
    @Prop() filter: undefined | ((val: number | string) => string);
    @Prop() display_width: string;

    once_input: boolean = false; //lazy
    is_minus: boolean = false;

    hour: number | string = "";
    minute: number | string = "";

    no_watch: boolean = false;
    hour_select_option = {};
    minute_select_option = {};

    get disp_value() {
      const hour = this.calcHour(this.value);
      const minute = this.calcMinute(this.value);
      const time_disp =
        validation.isset(hour) && validation.isset(minute)
          ? hour + (this.hour_label ?? "") + minute + (this.minute_label ?? "")
          : "";
      if (this.filter === undefined) {
        return time_disp;
      } else {
        return this.filter(time_disp);
      }
    }

    calcHour(val) {
      if (this.normal_time) return util.pad_num(Math.floor(Number(val) / 60));
      else return Math.floor(Number(val) / 60).toString();
    }
    calcMinute(val) {
      return util.pad_num(Number(val) % 60);
    }

    created() {
      this.set_hour_minute(this.value);
      if (this.hour_select !== undefined) {
        for (var tmp = this.min_hour_select; tmp <= this.max_hour_select; tmp += this.hour_select) {
          this.hour_select_option[tmp] = util.pad_num(tmp, 2);
        }
      }
      if (this.minute_select !== undefined) {
        for (var tmp = 0; tmp < 60; tmp += this.minute_select) {
          this.minute_select_option[tmp] = util.pad_num(tmp, 2);
        }
      }
    }

    @Watch("value")
    on_change_value(value, oldvalue) {
      if (!this.no_watch) this.set_hour_minute(value);
      this.no_watch = false;
    }

    set_hour_minute(value) {
      if (value !== null && !isNaN(value)) {
        if (value < 0) {
          this.is_minus = true;
          value += 1440;
        }
        let hour = this.calcHour(value);
        let minute = this.calcMinute(value);
        if (hour == "00") {
          this.hour = null;
        } else {
          this.hour = hour;
        }
        this.minute = minute;
      } else {
        this.hour = null;
        this.minute = null;
        this.$emit("input", null);
      }
    }

    ch_minus() {
      this.is_minus = !this.is_minus;
      if (this.value !== null && !isNaN(this.value)) {
        if (this.is_minus) {
          var time = this.value - 1440;
        } else {
          var time = this.value + 1440;
        }
        this.$emit("input", time);
      }
    }

    input_hour(e) {
      var val = e.target.value;
      if (isNaN(val)) {
        val = "";
      } else if (this.normal_time) {
        if (val > 48) {
          val = val[0];
        } else if (val < 0) {
          val = "";
        } else {
          if (val.length >= 2) {
            this.next_focus(e);
          } else if (val >= 5 && val < 10) {
            val = "0" + val;
            this.next_focus(e);
          } else if (!(val < 5 && val >= 0)) {
            val = "";
          }
        }
      }

      e.target.value = val;
      this.hour = val;
      if (val == "" && (this.minute == "" || this.minute == null)) {
        if (this.value != null) {
          // this.no_watch = true;
        }
        this.$emit("input", null);
      } else {
        const hour = Number(val) * 60;
        const minute = Number(this.minute);
        var time = hour + minute;
        if (this.disp_yesterday && this.is_minus) {
          time -= 1440;
        }
        if (this.value != time) {
          this.no_watch = true;
        }
        this.$emit("input", time);
      }
    }
    input_minute(e) {
      var val = e.target.value;

      if (this.normal_time) {
        if (val >= 60) {
          val = val[0] + val[1];
        } else if (val < 0) {
          val = "";
        } else {
          if (val.length >= 2) {
            this.next_focus(e);
          } else if (val >= 6 && val < 10) {
            val = "0" + val;
            this.next_focus(e);
          } else if (!(val < 6 && val >= 0)) {
            val = "";
          }
        }
      }

      e.target.value = val;
      this.minute = val;
      if ((val == null || val == "") && (this.hour == null || this.hour == "")) {
        if (this.value != null) {
          this.no_watch = true;
        }
        this.$emit("input", null);
      } else {
        const hour = Number(this.hour) * 60;
        const minute = Number(val);
        var time = hour + minute;
        if (this.disp_yesterday && this.is_minus) {
          time -= 1440;
        }
        if (this.value != time) {
          this.no_watch = true;
        }
        this.$emit("input", time);
      }
    }

    prev_focus(e) {
      const index = $(".text_focus").index(e.target);
      const prev_element = <HTMLInputElement>$(".text_focus")[index - 1];
      if (prev_element) {
        if (
          prev_element.type == "text" ||
          prev_element.type == "url" ||
          prev_element.type == "tel"
        ) {
          prev_element.selectionStart = 0;
          prev_element.selectionEnd = prev_element.value.length;
          prev_element.focus();
        }
      }
    }
    next_focus(e) {
      const index = $(".text_focus").index(e.target);
      const next_element = <HTMLInputElement>$(".text_focus")[index + 1];
      if (next_element) {
        if (
          next_element.type == "text" ||
          next_element.type == "url" ||
          next_element.type == "tel"
        ) {
          next_element.selectionStart = 0;
          next_element.selectionEnd = next_element.value.length;
          next_element.focus();
        }
      }
    }

    @Provide("is_edit")
    get use_is_edit() {
      if (this.is_edit_custom !== undefined) {
        return this.is_edit_custom;
      } else {
        return this.is_edit;
      }
    }
    @Provide("is_add")
    get use_is_add() {
      if (this.is_add_custom !== undefined) {
        return this.is_add_custom;
      } else {
        return this.is_add;
      }
    }
    get is_input() {
      return this.use_is_edit || this.use_is_add;
    }

    get style() {
      return {
        "--display_width": this.display_width,
      };
    }
  }
