
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinForm from "@/mixins/mixinForm";
  import FPartsToggle from "@/components/f/parts/Toggle.vue";

  @Component({
    components: {
      FPartsToggle,
    },
  })
  export default class Normal extends Mixins(mixinForm) {
    @Prop() value: any;
    @Prop({ default: false }) multi: boolean;
    @Prop({ default: "null" }) this_value: any;
    @Prop({ default: "無効" }) invalidstring: string;
    @Prop({ default: "有効" }) validstring: string;
    @Prop({ default: "#999999" }) invalidcolor: string;
    @Prop({ default: "#4da6ff" }) validcolor: string;
    @Prop({ default: false }) disabled: boolean | boolean[];
    @Prop() disable_reason: any;
    @Prop() color: string;
    @Prop() options: { [id: number | string]: number | string };

    get use_options() {
      const options = {};
      Object.keys(this.options).forEach((key) => {
        options[key] = this.options[key];
      });
      return options;
    }

    get tmp_value() {
      return this.value;
    }
    set tmp_value(val) {
      this.$emit("input", val);
    }

    element(e) {
      this.$emit("element", e);
    }
  }
