
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FDate from "@/components/f/Date.vue";

@Component({
  components: {
    LItem,
    FDate,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'social_ins_got_on';
    this._info = 'info.social_ins_got_on';
  }

  get condition() {
    return (this.object.health_ins_type == 1 || this.object.pension_ins_type == 1);
  }
}
