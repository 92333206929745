
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FZip from "@/components/f/Zip.vue";

@Component({
  components: {
    LItem,
    FZip,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'pension_ins_id';
  }

  get condition() {
    return this.object.pension_ins_type == 1;
  }
}
