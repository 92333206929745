import { render, staticRenderFns } from "./Ishol.vue?vue&type=template&id=fd612b86&scoped=true&lang=pug"
import script from "./Ishol.vue?vue&type=script&lang=ts"
export * from "./Ishol.vue?vue&type=script&lang=ts"
import style0 from "./Ishol.vue?vue&type=style&index=0&id=fd612b86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd612b86",
  null
  
)

export default component.exports