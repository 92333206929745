
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FToggle from "@/components/f/Toggle.vue";
import FCheck from "@/components/f/Check.vue";

@Component({
  components: {
    LItem,
    FToggle,
    FCheck,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'holiday_allowance';
    this._need = true;
    this.auth_shift = false;
    this.auth_nencho = false;
  }

  check_hol() {
    if (!this.object.is_outhol && !this.object.is_lawhol) {
      this.object.is_hol = 0;
    }
  }
  check_is_hol() {
    if (this.object.is_hol != 0) {
      if (!this.object.is_outhol && !this.object.is_lawhol) {
        this.object.is_outhol = 1;
        this.object.is_lawhol = 1;
      }
    }
  }
}
