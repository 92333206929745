
  import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
  import * as util from "@/util";
  import $ from "jquery";
  import validation from "@/library/validation";
  import mixinForm from "@/mixins/mixinForm";
  import { Inject, Provide } from "@/library/provide";
  import FSelect from "@/components/f/Select.vue";
  import FText from "@/components/f/Text.vue";
  import filter from "@/library/filter";

  @Component({
    components: {
      FSelect,
      FText,
    },
  })
  export default class Pager extends Mixins(mixinForm) {
    @Prop() value: string | null;
    @Prop({ default: "年" }) year_label;
    @Prop({ default: "月" }) month_label;
    @Prop({ default: "日" }) day_label;
    @Prop({ default: "120px" }) year_width: string;
    @Prop({ default: "30px" }) month_width: string;
    @Prop({ default: "30px" }) day_width: string;

    @Prop() max_year: number;
    @Prop({ default: 1910 }) min_year: number;
    @Prop({ default: 0 }) plus_max_year: number;
    @Prop() minus_min_year: number;
    @Prop() default_year: number;
    @Prop({ default: false }) is_nendo: boolean;

    @Prop() filter: undefined | ((val: string) => string);

    year: number | string = "";
    month: number | string = "";
    day: number | string = "";

    no_watch: boolean = false;

    get is_nendo_year() {
      return this.$i18n.locale == "ja" && this.is_nendo;
    }

    @Watch("value")
    onChangeValue(val) {
      this.set_date(val);
    }

    get disp_value() {
      if (this.value) {
        if (this.filter !== undefined) {
          const filtered_value = this.filter(this.value);
          const split_date = filtered_value.split("-");
          if (split_date.length == 3) {
            return filter.date(this.value);
          } else {
            return filtered_value;
          }
        } else {
          const split_date = this.value.split("-");
          if (split_date.length == 3) {
            return filter.date(this.value);
          } else {
            if (this.filter !== undefined) {
              return this.filter(this.value);
            } else {
              return this.value;
            }
          }
        }
      } else {
        if (this.filter !== undefined) {
          return this.filter(this.value);
        } else {
          return this.value;
        }
      }
    }

    created() {
      this.set_date(this.value);
      this.init_year(this.value);
    }

    get option_hash() {
      const tmp_options = {};
      var max_year = this.max_year;
      if (max_year === undefined) {
        const date = new Date();
        max_year = Number(util.formatDate(date, "yyyy"));
        max_year += this.plus_max_year;
      }

      var min_year = this.min_year;
      if (this.minus_min_year !== undefined) {
        const date = new Date();
        min_year = Number(util.formatDate(date, "yyyy"));
        min_year -= this.minus_min_year;
      }

      for (var i = max_year; i >= min_year; i--) {
        if (this.is_nendo_year) {
          tmp_options[i] = util.year2nengo(i);
        } else {
          tmp_options[i] = i;
        }
      }
      return tmp_options;
    }

    get options() {
      var keys = Object.keys(this.option_hash);
      keys.sort((a, b) => {
        return a < b ? 1 : -1;
      });

      const options = [];
      keys.forEach((key) => {
        options.push({ key: key, value: this.option_hash[key] });
      });

      return options;
    }

    set_date(value) {
      if (value) {
        var split_date = value.split("-");
        if (split_date.length == 3) {
          this.year = split_date[0];
          this.month = split_date[1];
          this.day = split_date[2];
        }
      }
    }

    init_year(value) {
      const date = new Date();

      if (!value) {
        this.year = null;
        this.month = null;
        this.day = null;
      }

      if (this.year === null) {
        if (this.default_year) {
          this.year = this.default_year;
        } else {
          this.year = Number(util.formatDate(date, "yyyy"));
        }
        if (this.option_hash[this.year] === undefined) {
          this.year = this.options[0].key;
        }
      }
    }

    emit_date() {
      if (
        this.year != "" &&
        this.year != null &&
        this.month != "" &&
        this.month != null &&
        this.month != 0 &&
        this.day != "" &&
        this.day != null &&
        this.day != 0
      ) {
        let date = this.year + "-" + this.month + "-" + this.day;
        if (util.is_valid_date(date)) {
          if (this.value != date) {
            this.no_watch = true;
          }
          this.$emit("input", date);
        } else {
          if (this.value != null) {
            this.no_watch = true;
          }
          this.$emit("input", null);
        }
      } else {
        if (this.value != null) {
          this.no_watch = true;
        }
        this.$emit("input", null);
      }
    }

    input_year(e) {
      var val = e.target.value;

      if (val == "null") {
        val = null;
      }

      this.year = val;
      this.emit_date();
    }
    input_month(e) {
      var val = e.target.value;

      if ((val != "" && (val > 12 || val < 0)) || val.length > 2) {
        e.target.value = this.month;
      } else {
        this.month = val;
      }

      if (val > 1 || val.length >= 2) {
        this.next_focus(e);
      }

      this.emit_date();
    }
    input_day(e) {
      var val = e.target.value;

      if ((val != "" && (val > 31 || val < 0)) || val.length > 2) {
        e.target.value = this.day;
      } else {
        this.day = val;
      }

      if (val > 4 || val.length >= 2) {
        this.next_focus(e);
      }

      this.emit_date();
    }

    prev_focus(e) {
      const index = $(".text_focus").index(e.target);
      const prev_element = <HTMLInputElement>$(".text_focus")[index - 1];
      if (prev_element) {
        if (
          prev_element.type == "text" ||
          prev_element.type == "url" ||
          prev_element.type == "tel"
        ) {
          prev_element.selectionStart = 0;
          prev_element.selectionEnd = prev_element.value.length;
          prev_element.focus();
        }
      }
    }
    next_focus(e) {
      const index = $(".text_focus").index(e.target);
      const next_element = <HTMLInputElement>$(".text_focus")[index + 1];
      if (next_element) {
        if (
          next_element.type == "text" ||
          next_element.type == "url" ||
          next_element.type == "tel"
        ) {
          next_element.selectionStart = 0;
          next_element.selectionEnd = next_element.value.length;
          next_element.focus();
        }
      }
    }

    @Provide("is_edit")
    get use_is_edit() {
      if (this.is_edit_custom !== undefined) {
        return this.is_edit_custom;
      } else {
        return this.is_edit;
      }
    }
    @Provide("is_add")
    get use_is_add() {
      if (this.is_add_custom !== undefined) {
        return this.is_add_custom;
      } else {
        return this.is_add;
      }
    }
    get is_input() {
      return this.use_is_edit || this.use_is_add;
    }
  }
