
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    LItem,
    FSelect,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'employee_type';
    this._info = 'info.employee_type';
    this._popup_title = 'employee_type';
    this._popup_content = 'popup.employee_type';
    this._need = true;
  }
}
