
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import LItem from "@/components/l/Item.vue";
import FToggle from "@/components/f/Toggle.vue";
import FDate from "@/components/f/Date.vue";

@Component({
  components: {
    LItem,
    FToggle,
    FDate,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'child_care';
    this._popup_title = 'child_care';
    this._popup_content = 'popup.child_care';
  }

  get condition() {
    return this.object.health_ins_type == 1 || this.object.pension_ins_type == 1;
  }
}
